import React from 'react'
import "./home.css"

function Home() {
  return (
    <div className='test'>KAUSHAL KHATIWADA</div>
    
  )
}

export default Home